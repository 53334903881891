


    html {
        width: 100%;
        min-height: 100vh;
    }
    body {
        /*position: fixed;*/
        top: 0;
        left: 0;
        font-family: 'Lato', sans-serif;
        background-color: #202932;
        background-image: linear-gradient(to top, #0f527c, #110b1f);
        background-attachment: fixed;
        overflow: hidden;
        width: 100%;
        /*min-height: 100vh;*/
        /*height: 4000px;*/
        margin: 0;
        padding: 0;
        float: left;
    }


    @media (max-width: 599px) {
        .anim{
            display: none;
        }
        body{
            overflow: auto !important;
        }

    }

    .blog-card {
    transition: height 0.3s ease;
    -webkit-transition: height 0.3s ease;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 3px 7px -3px rgba(0, 0, 0, 0.3);
    margin: 2em;
    overflow: hidden;
    position: relative;
    font-size: 14px;
    line-height: 1.45em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;




}

a.offre {
    color: white !important;
    position: absolute;
    right: 5%;
    top: 50%;
}


.sendc {
    margin-right: 0.6em;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 2;

}

@media (min-width: 800px) {
    .sendc {
        margin-right: 1.5em;
    }
}



/*quand on est sur petit écran les cards deviennent un tableau*/

@media (max-width: 599px) {
    .flex {
        display: none;
    }

    .grand {
        display: none !important;
    }

}



@media (min-width: 600px) {


    footer a{
        margin : 3em;
    }


    .offres {
        display: none;
    }



    .flex {
        top : 15%;
        position: absolute;
        width: 100%;
        height: 80%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        overflow-y: scroll;
        padding-right: 25px;
        box-sizing: content-box;

    }



}


.blog-card:hover .details {
    left: 0;
}

.blog-card .photo {
    height: 200px;
    position: relative;
}

.photo img {
    max-height: 100%;
}

.blog-card .photo.photo1 {
    background: url("https://cdn.pixabay.com/photo/2016/01/19/14/47/laptop-work-1148958_960_720.jpg") center no-repeat;
    background-size: cover;
}

.blog-card .photo.photo2 {
    background: url("https://cdn.pixabay.com/photo/2017/09/27/10/30/robot-2791671_960_720.jpg") center no-repeat;
    background-size: cover;
}

.blog-card .details {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    background: rgba(0, 0, 0, 0.6);
    box-sizing: border-box;
    color: #fff;
    font-family: "Open Sans";
    list-style: none;
    margin: 0;
    padding: 10px 15px;
    height: 200px;
    /*POSITION*/
    position: absolute;
    top: 0;
    left: -100%;
}

.blog-card .details > li {
    padding: 3px 0;
}


.blog-card .details .tags ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.blog-card .details .tags ul:before {
    /*content: "\f02b";*/
}

.blog-card .details .tags li {
    display: inline-block;
    margin-right: 3px;
}

.blog-card .details a {
    color: inherit;
    border-bottom: 1px dotted;
}

.blog-card .details a:hover {
    color: #d13434;
}

.blog-card .description {
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    height: 100%;
}

.blog-card .description h1 {
    font-family: "Roboto";
    line-height: 1em;
    margin: 0 0 10px 0;
}

.blog-card .description h2 {
    color: #9b9b9b;
    font-family: "Open Sans";
    line-height: 1.2em;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 400;
    margin: 1.2% 0;
}

.blog-card .description p {
    position: relative;
    margin: 0;
    padding-top: 20px;
}

.blog-card .description p:after {
    content: "";
    background: #d13434;
    height: 6px;
    width: 40px;
    /*POSITION*/
    position: absolute;
    top: 6px;
    left: 0;
}

.blog-card .description a {
    color: #d13434;
    margin-bottom: 10px;
    float: right;
}

.blog-card .description a:after {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    margin-left: -10px;
    opacity: 0;
    vertical-align: middle;
}

.blog-card .description a:hover:after {
    margin-left: 5px;
    opacity: 1;
}

@media screen and (min-width: 600px) {
    .blog-card {
        height: 155px;
        max-width: 600px;
    }

    .blog-card:hover .photo {
        -webkit-transform: rotate(5deg) scale(1.3);
        transform: rotate(5deg) scale(1.3);
    }

    .blog-card .photo {
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        float: left;
        height: 100%;
        width: 40%;
    }

    .blog-card .details {
        width: 40%;
    }

    .blog-card .description {
        float: left;
        width: 60%;
        z-index: 0;
    }

    .blog-card .description:before {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        content: "";
        background: #fff;
        width: 100%;
        z-index: -1;
        /*POSITION*/
        position: absolute;
        left: -15px;
        top: 0;
        bottom: 0;
    }
}
